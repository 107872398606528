import React, { Component } from 'react';
import { apiRequest } from '../Utils/ApiRequest'
import '../Utils/GridLocale'
import * as yup from 'yup';
import '../App.css';
import './Comunes.css'

let $ = window.$;
let JSZip = window.JSZip;

export default class VendedorScreen extends Component {
	initialState = {
		data: {
			id: '',
			codigo: '',
			nombre_completo: '',
			usuario: '',
			password: '',
			correo: '',
			permiso_crear_plan: true,
			permiso_crear_orden: true,
			permiso_admin_web: false,
			permiso_modif_cuenta: false,
		},
		creacionNuevo: true,
		enviando: false,
		cargandoLista: false,
		columnDefs: [
			{
				dataField: "codigo",
				caption: "COD. USUARIO",
				alignment: 'right',
			}, {
				dataField: "nombre_completo",
				caption: "NOMBRE COMPLETO",
			}, {
				dataField: "usuario",
				caption: "USERNAME",
			}, {
				dataField: "password",
				caption: "PASSWORD",
			}, {
				dataField: "correo_electronico",
				caption: "EMAIL",
			}, {
				caption: "MODIFICAR",
				allowSorting: false,
				allowGrouping: false,
				cellTemplate: (container, options) => {
					$('<div />').dxButton({
						icon: 'edit',
						type: 'danger',
						onClick: (e) => {
							this.setState({ creacionNuevo: false, data: options.data })
						}
					}).appendTo(container);
				}
			}, {
				caption: "ELIMINAR",
				allowSorting: false,
				allowGrouping: false,
				cellTemplate: (container, options) => {
					$('<div />').dxButton({
						icon: 'trash',
						type: 'danger',
						onClick: (e) => {
							this.eliminar(options.data);
						}
					}).appendTo(container);
				}
			}]
	}

	constructor(props) {
		super(props);
		this.state = { ...this.initialState }
	}

	componentDidMount() {
		this.triggerListRefresh();
	}

	componentDidUpdate(prevProps, prevState) {
		$("#gridContainer").dxDataGrid({
			dataSource: this.state.listado,
			paging: {
				pageSize: 20
			},
			summary: this.state.colSummary,
			pager: {
				showPageSizeSelector: true,
				allowedPageSizes: [50, 100, 150, 200]
			},
			remoteOperations: false,
			searchPanel: {
				visible: true,
				highlightCaseSensitive: true
			},
			groupPanel: { visible: true },
			grouping: {
				autoExpandAll: false
			},
			allowColumnReordering: true,
			rowAlternationEnabled: true,
			showBorders: true,
			columns: this.state.columnDefs,
			columnAutoWidth: true,
			filterRow: {
				visible: true,
				applyFilter: "auto"
			},
			searchPanel: {
				visible: true,
				width: 240,
				placeholder: "Buscar..."
			},
			export: {
				enabled: true,
			},
			headerFilter: {
				visible: true
			},
			onContentReady: function (e) { }
		});
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState(prevState => {
			let data = { ...prevState.data }
			if (typeof value === "string") {
				value = value.toUpperCase();
			}
			data[name] = value;
			return { ...prevState, data: data }
		});
	}

	handleInputChangeKeepCase = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState(prevState => {
			let data = { ...prevState.data }
			data[name] = value;
			return { ...prevState, data: data }
		});
	}

	eliminar = (data) => {
		if (data.usuario == 'BOLTRACK') {
			alert("No se puede eliminar la cuenta de administracion.");
			return;
		}
		if (window.confirm(`Seguro que desea eliminar la cuenta ${data.usuario} - ${data.nombre_completo}`)) {
			apiRequest('/vendedores',
				{
					accion: "eliminacion",
					data: data
				}, (res) => {
					if (res.success) {
						alert(`Se elimino la cuenta exitosamente.`);
						this.triggerListRefresh();
					} else {
						alert(res.msg)
					}
				})
		}
	}

	validarFormulario = () => {
		let schema = yup.object().shape({
			//id: yup.number(),
			codigo: yup.string(),
			nombre_completo: yup.string().required("El nombre es obligatorio"),
			usuario: yup.string().required("El nombre de usuario es obligatorio"),
			password: yup.string().required("Una contraseña es obligatoria").min(5, "La longitud minima de la contraseña es de 5 caracteres"),
			correo: yup.string().email("El correo no esta en un formato valido."),
			permiso_crear_plan: yup.boolean(),
			permiso_crear_orden: yup.boolean(),
			permiso_admin_web: yup.boolean(),
			permiso_modif_cuenta: yup.boolean(),
		});
		try {
			let isValid = schema.validateSync(this.state.data, { abortEarly: false });
			if (isValid) {
				return true;
			}
		} catch (err) {
			alert(err.errors.join("\n"))
		};
		return false;
	}

	limpiarFormulario = () => {
		this.setState({ data: { ...this.initialState.data },creacionNuevo:true });
	}

	enviarDatos = () => {
		if (this.validarFormulario()) {
			let accionARealizar = this.state.creacionNuevo ? 'creacion' : 'modificacion';
			apiRequest('/vendedores',
				{
					accion: accionARealizar,
					data: this.state.data
				}, (res) => {
					if (res.success) {
						alert(`Se realizo la ${accionARealizar} exitosamente.`)
						this.triggerListRefresh();
					} else {
						alert(res.msg)
					}
				})
		}
	}

	triggerListRefresh = () => {
		this.setState({ cargandoLista: true }, () => {
			apiRequest('/vendedores',
				{
					accion: 'listado',
					data: this.state.data
				}, (res) => {
					if (res.success) {
						this.setState({
							cargandoLista: false,
							listado: res.data.map(v => {v.codigo=v.codigo_interno;v.id = v.usuario_id; return v}),
						})
					} else {
						alert(res.msg)
					}
				})
		});
	}

	render() {
		return <>
			<h1>Gestion de Vendedores / Usuarios</h1>
			<div className="campos">
				<label htmlFor="codigo">
					<span>Codigo del Usuario:</span>
					<input name="codigo" onChange={this.handleInputChange} value={this.state.data.codigo} />
				</label>
				<label htmlFor="nombre_completo">
					<span>Nombre Completo:</span>
					<input name="nombre_completo" onChange={this.handleInputChange} value={this.state.data.nombre_completo} />
				</label>
				<label htmlFor="usuario">
					<span>Nombre de Usuario:</span>
					<input name="usuario" onChange={this.handleInputChange} value={this.state.data.usuario} />
				</label>
				<label htmlFor="password">
					<span>Password:</span>
					<input name="password" onChange={this.handleInputChange} value={this.state.data.password} />
				</label>
				<label htmlFor="correo">
					<span>Correo:</span>
					<input name="correo" onChange={this.handleInputChangeKeepCase} value={this.state.data.correo} />
				</label>
				<label htmlFor="permiso_crear_plan">
					<span>Permiso Creacion de Planes:</span>
					<input name="permiso_crear_plan" type="checkbox" onChange={this.handleInputChange} checked={this.state.data.permiso_crear_plan} />
				</label>
				<label htmlFor="permiso_crear_orden">
					Permiso Creacion de Ordenes:
								<input name="permiso_crear_orden" type="checkbox" onChange={this.handleInputChange} checked={this.state.data.permiso_crear_orden} />
				</label>
				<label htmlFor="permiso_admin_web">
					Permiso Uso de Web:
								<input name="permiso_admin_web" type="checkbox" onChange={this.handleInputChange} checked={this.state.data.permiso_admin_web} />
				</label>
				<label htmlFor="permiso_modif_cuenta">
					Permiso Modificacion de Usuarios:
								<input name="permiso_modif_cuenta" type="checkbox" onChange={this.handleInputChange} checked={this.state.data.permiso_modif_cuenta} />
				</label>
			</div>
			<button className="sendBtn" type="submit" onClick={this.enviarDatos}>
				{this.state.creacionNuevo ? "Crear Nuevo" : "Modificar Seleccionado"}
			</button>
			<button className="resetBtn" type="submit" onClick={this.limpiarFormulario}>
				Limpiar Formulario
						</button>
			<div
				id="gridContainer"
				style={{
					height: "100%",
					maxWidth: "800px"
				}}
			/>


		</>;
	}
}
