import React, { Component } from 'react';

import { FadeInEnterLeave } from './Components/UI/Transitions'

import LoginScreen from './Components/LoginScreen'
import MainScreen from './Components/MainScreen'
import { setToken, clearToken } from './Session/Session'
import './App.css';

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			token: "",
			nombre_empresa: "",
			nombre_usuario: "",
		}
	}

	onLogout = () => {
		clearToken();
		this.setState({ token: '' });
	}

	onLogin = (data) => {
		setToken(data.token);
		this.setState({
			token: data.token,
			nombre_usuario: data.nombre_usuario,
			nombre_empresa: data.nombre_empresa,
		});
	}


	render() {
		return (
			<div className="App">
				{(this.state.token === "") ?
					<LoginScreen key="login" onLogin={this.onLogin} />
					: <MainScreen key="main" nombre_empresa={this.state.nombre_empresa} nombre_usuario={this.state.nombre_usuario} onLogout={this.onLogout} />}
			</div>
		);
	}
}

export default App;
