import React, { Component } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './Transitions.css'

var FadeInAppear = (props) => (
	<ReactCSSTransitionGroup
		component={React.Fragment}
		transitionName="transitionFadeIn"
		transitionAppear={true}
		transitionAppearTimeout={1000}
		transitionEnter={false}
		transitionLeave={false}
	>
		{props.children}
	</ReactCSSTransitionGroup>
)

var FadeInEnterLeave = (props) => (
	<ReactCSSTransitionGroup
		component={React.Fragment}
		transitionName="transitionFadeEnterLeave"
		transitionAppear={false}
		transitionEnterTimeout={500}
		transitionEnter={true}
		transitionLeaveTimeout={500}
		transitionLeave={true}
	>
		{props.children}
	</ReactCSSTransitionGroup>
)


export {FadeInAppear,FadeInEnterLeave};