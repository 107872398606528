import React, { Component } from 'react';
import { apiRequest } from '../Utils/ApiRequest'
import '../Utils/GridLocale'
import * as yup from 'yup';
import '../App.css';
import './Comunes.css'

let $ = window.$;
let JSZip = window.JSZip;

export default class TemaVisitaScreen extends Component {
	initialState = {
		data: {
			id: '',
			empresa_id: '',
			nombre: '',
		},
		creacionNuevo: true,
		enviando: false,
		cargandoLista: false,
		columnDefs: [
			{
				dataField: "nombre",
				caption: "TEMA DE VISITA",			
			}, {
				caption: "MODIFICAR",
				allowSorting: false,
				allowGrouping: false,
				cellTemplate: (container, options) => {
					$('<div />').dxButton({
						icon: 'edit',
						type: 'danger',
						onClick: (e) => {
							this.setState({ creacionNuevo: false, data: options.data })
						}
					}).appendTo(container);
				}
			}, {
				caption: "ELIMINAR",
				allowSorting: false,
				allowGrouping: false,
				cellTemplate: (container, options) => {
					$('<div />').dxButton({
						icon: 'trash',
						type: 'danger',
						onClick: (e) => {
							this.eliminar(options.data);
						}
					}).appendTo(container);
				}
			}]
	}

	constructor(props) {
		super(props);
		this.state = { ...this.initialState }
	}

	componentDidMount() {
		this.triggerListRefresh();
	}

	componentDidUpdate(prevProps, prevState) {
		$("#gridContainer").dxDataGrid({
			dataSource: this.state.listado,
			paging: {
				pageSize: 20
			},
			summary: this.state.colSummary,
			pager: {
				showPageSizeSelector: true,
				allowedPageSizes: [50, 100, 150, 200]
			},
			remoteOperations: false,
			searchPanel: {
				visible: true,
				highlightCaseSensitive: true
			},
			groupPanel: { visible: true },
			grouping: {
				autoExpandAll: false
			},
			allowColumnReordering: true,
			rowAlternationEnabled: true,
			showBorders: true,
			columns: this.state.columnDefs,
			columnAutoWidth: true,
			filterRow: {
				visible: true,
				applyFilter: "auto"
			},
			searchPanel: {
				visible: true,
				width: 240,
				placeholder: "Buscar..."
			},
			export: {
				enabled: true,
			},
			headerFilter: {
				visible: true
			},
			onContentReady: function (e) { }
		});
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState(prevState => {
			let data = { ...prevState.data }
			if (typeof value === "string") {
				value = value.toUpperCase();
			}
			data[name] = value;
			return { ...prevState, data: data }
		});
	}

	handleInputChangeKeepCase = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState(prevState => {
			let data = { ...prevState.data }
			data[name] = value;
			return { ...prevState, data: data }
		});
	}

	eliminar = (data) => {
		if (window.confirm(`Seguro que desea eliminar el Tema De Visita ${data.nombre_tarea}`)) {
			apiRequest('/tema_visitas',
				{
					accion: "eliminacion",
					data: data
				}, (res) => {
					if (res.success) {
						alert(`Se elimino el tema de visita exitosamente.`);
						this.triggerListRefresh();
					} else {
						alert(res.msg)
					}
				})
		}
	}

	validarFormulario = () => {
		let schema = yup.object().shape({
			nombre: yup.string().required("El nombre es obligatorio"),
		});
		try {
			let isValid = schema.validateSync(this.state.data, { abortEarly: false });
			if (isValid) {
				return true;
			}
		} catch (err) {
			alert(err.errors.join("\n"))
		};
		return false;
	}

	limpiarFormulario = () => {
		this.setState({ data: { ...this.initialState.data },creacionNuevo:true });
	}

	enviarDatos = () => {
		if (this.validarFormulario()) {
			let accionARealizar = this.state.creacionNuevo ? 'creacion' : 'modificacion';
			apiRequest('/tema_visitas',
				{
					accion: accionARealizar,
					data: this.state.data
				}, (res) => {
					if (res.success) {
						alert(`Se realizo la ${accionARealizar} exitosamente.`)
						this.triggerListRefresh();
					} else {
						alert(res.msg)
					}
				})
		}
	}

	triggerListRefresh = () => {
		this.setState({ cargandoLista: true }, () => {
			apiRequest('/tema_visitas',
				{
					accion: 'listado',
					data: this.state.data
				}, (res) => {
					if (res.success) {
						this.setState({
							cargandoLista: false,
							listado: res.data,
						})
					} else {
						alert(res.msg)
					}
				})
		});
	}

	render() {
		return <>
			<h1>Gestion de Temas de Visita</h1>
			<div className="campos">
				<label htmlFor="nombre">
					<span>Nombre del Tema de Visita:</span>
					<input name="nombre" onChange={this.handleInputChange} value={this.state.data.nombre} />
				</label>
			</div>
			<button className="sendBtn" type="submit" onClick={this.enviarDatos}>
				{this.state.creacionNuevo ? "Crear Nuevo" : "Modificar Seleccionado"}
			</button>
			<button className="resetBtn" type="submit" onClick={this.limpiarFormulario}>
				Limpiar Formulario
						</button>
			<div
				id="gridContainer"
				style={{
					height: "100%",
					maxWidth: "800px"
				}}
			/>


		</>;
	}
}
