import React, { Component } from 'react';
import '../App.css';
import './OrdenPedido.css'



export default class OrdenPedidoScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ordenes:[]
		}
	}

	componentDidMount() {
		fetch("http://192.175.109.132:4000/obtener_ordenes",{method:'POST',body:JSON.stringify({
token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwiZW1wcmVzYV9pZCI6Miwibm9tYnJlcyI6IkRFTU8iLCJhcGVsbGlkb19wYXQiOiJCb2x0cmFjayIsImFwZWxsaWRvX21hdCI6IiIsInRlbGVmb25vIjoiIiwiZW1haWwiOiIiLCJ1c3VhcmlvIjoiYm9sdHJhY2siLCJwYXNzd29yZCI6ImJvbHRyYWNrIiwiY29kaWdvX3VzdWFyaW8iOiJERU1PLUJMVFJLIiwidXN1YXJpb19pZCI6NCwiY29kaWdvX2ludGVybm8iOiIiLCJwZXJtaXNvX2NyZWFyX3BsYW4iOjEsInBlcm1pc29fY3JlYXJfb3JkZW4iOjEsInBlcm1pc29fYWRtaW5fd2ViIjoxLCJwZXJtaXNvX2NyZWFyX2N1ZW50YSI6MSwiY29ycmVvc19wb3JfZGVmZWN0byI6InNjZXNwZWRlc0Bib2x0cmFjay5jb20uYm8iLCJub21icmVfZW1wcmVzYSI6IkRFTU8gQk9MVFJBQ0siLCJub21icmVjb21wbGV0byI6IkJvbHRyYWNrICwgREVNTyIsImlhdCI6MTU1MDIwMTY3OX0.w4caFMMGrZBMWmSV4CjSZ_GYcOHSg9h5SVvPSWvmnTA' }),headers: {
         'Content-Type': 'application/json'}}).then(d=>d.json()).then(d => this.setState({ordenes:d.ordenes || []}));
	}

	render() {
		return <>
		<h1>Ordenes de Pedido</h1>
		<div style={{display:'flex'}} className="container">
		<div className="tbl-header">
    <table cellPadding="0" cellSpacing="0" >
      <thead>
        <tr>
          <th>Razon Social</th>
          <th>Fecha Creacion</th>
          <th>Fecha Compromiso</th>
          <th>Campaña</th>
          <th>Técnico de Venta</th>
					<th>Ver Orden</th>
        </tr>
      </thead>
    </table>
  </div>
  <div className="tbl-content">
    <table cellPadding="0" cellSpacing="0">
      <tbody>
       {
				 this.state.ordenes.map((o) => (
						<tr key={o.orden_id}>
							<td>{o.razon_social}</td>
							<td>{o.fecha_creacion.substring(0,10)}</td>
							<td>{o.fecha_compromiso.substring(0,10)}</td>
							<td>{o.nombre_campana}</td>
							<td>{o.nombres+" "+o.apellido_pat+" "+o.apellido_mat}</td>
							<td><a href={"http://192.175.109.132:4000/"+o.orden_id+".html"} target="_blank">Ver orden</a></td>
						</tr>
				 ))
			 }

      </tbody>
    </table>
  </div>
	</div>
		</>;
	}
}
