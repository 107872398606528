import React, { Component, ChangeEvent } from 'react';

import {FadeInAppear} from './UI/Transitions'

import '../App.css';
import './LoginScreen.css';
import {apiRequest} from '../Utils/ApiRequest'
import spinner from '../Assets/spinner4.svg'



class LoginScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			error_msg: '',
			loading: false,
		}
	}

	onUsernameChange = (elem) => {
		this.setState({ username: elem.target.value });
	}

	onPasswordChange = (elem) => {
		this.setState({ password: elem.target.value });
	}

	onLogin = () => {
		this.setState({ loading: true });

		apiRequest('/login',{username:this.state.username,password:this.state.password}, (data)=> {
			if (data.success) {
				this.props.onLogin(data);
			} else {
				this.setState({ loading: false, error_msg: data.msg });	
			}
		})
	}

	render() {
		return (
			<>
				<div className="fs f">
					<FadeInAppear>
						<div className="login_box">
							<img id="logo" src={process.env.PUBLIC_URL + "/img/logo_bgwhite.png"}></img>
							<span className="title">{/*Plataforma de Gestión de Flota Vehicular*/}Sistema de Visitas y Ventas Moviles</span>
							<div className="login_fields">
								<div className="field_line">
									<span className="fieldname">Usuario:</span>
									<input
										value={this.state.username}
										onChange={this.onUsernameChange}
										className="login_input"
									/>
								</div>
								<div className="field_line">
									<span className="fieldname">Contraseña:</span>
									<input
										value={this.state.password}
										onChange={this.onPasswordChange}
										className="login_input"

										type="password" />
								</div>
								{
									(this.state.error_msg.length > 0)
									&& <span className="error_msg fade">{this.state.error_msg}</span>
								}
								<div className="field_line">
									{!this.state.loading ?
										<button
											onClick={this.onLogin}
											className="login_button"
										>Ingresar</button> :
										<button
											className="login_button"
										><img className="spinner" src={spinner} /></button>
									}
								</div>
							</div>
						</div>
					</FadeInAppear>
				</div>
			</> 
		);
	}
}

export default LoginScreen;
