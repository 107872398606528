const sessionKey = "SESSION"

function setToken(t) {
	localStorage.setItem(sessionKey,t);
}

function getToken() {
	return localStorage.getItem(sessionKey);
}

function clearToken() {
	localStorage.setItem(sessionKey,'');
}


export { setToken, clearToken, getToken };