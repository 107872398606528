import { getToken } from '../Session/Session'

let BASE_URL = "http://35.237.133.66:9100"
//BASE_URL = "http://localhost:9100"

let apiRequest = (endpoint, body, cb) => {
	console.log("fetch <- "+BASE_URL+endpoint)
	fetch(BASE_URL+endpoint,{
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({token:getToken(),...body})
	}).then(res => res.json()).then(res => cb(res));
}

export {apiRequest};
