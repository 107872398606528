import React, { Component, ChangeEvent } from 'react';
import {BrowserRouter as Router, Route, NavLink } from 'react-router-dom';

import '../App.css';
import './MainScreen.css';
import Logo from '../Assets/Arrow';
import VisitasScreen from './VisitasScreen'
import TipoTareaScreen from './TipoTareaScreen'
import ProductosScreen from './ProductosScreen'
import ClientesScreen from './ClientesScreen'
import TemaVisitaScreen from './TemaVisitaScreen'
import VendedorScreen from './VendedorScreen'
import CompetenciaScreen from './CompetenciaScreen'
import ReportesScreen from './ReportesScreen';
import OrdenPedidoScreen from './OrdenPedidoScreen';
import CampanaScreen from './CampanaScreen';




class MainScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSidebarOpen: true,
		}
	}

	toggleSidebar = () => {
		this.setState({isSidebarOpen:!this.state.isSidebarOpen});
	}

	render() {
		//return <div className="fs f"><h1>Daashhhhhboaaarrddd!</h1><button onClick={this.props.onLogout}>logout!</button></div>;
		return <div className="fs f">
				<div className="topbar">
					<div className="title ml">
						{/*"Plataforma de Gestión de Flota Vehicular*/}
						Sistema de Visitas y Ventas Moviles
					</div>
					<div className="userInfo">
						<div className="userFld"><span className="categoria">Empresa:</span> {this.props.nombre_empresa}</div>
						<div className="userFld"><span className="categoria">Usuario:</span> {this.props.nombre_usuario}</div>
					</div>
					<button className="logoutBtn" onClick={this.props.onLogout}>Cerrar Sesión</button>
				</div>
				<Router>

				<div className="body">
					<div className={this.state.isSidebarOpen? "sidebar openWidth": 'sidebar closeWidth'}>
						<button 
							className="toggleSidebar"
							onClick={this.toggleSidebar}
							><Logo direction={this.state.isSidebarOpen?'l':'r'}/>
						</button>
						{this.state.isSidebarOpen && <div 
							className="fs">
							<NavLink  to="/visitas"><div className="sidebarBtn">Administrar Visitas</div></NavLink >
							<NavLink  to="/orden_pedido"><div className="sidebarBtn">Ordenes de Pedido</div></NavLink >
							<NavLink  to="/vendedores"><div className="sidebarBtn">Catalogo de Vendedores / Usuarios</div></NavLink >
							<NavLink  to="/clientes"><div className="sidebarBtn">Registro de Clientes y Sucursales</div></NavLink >
							<NavLink  to="/tipo_tarea"><div className="sidebarBtn">Registro de Tipos de Tarea</div></NavLink >
							<NavLink  to="/tema_visita"><div className="sidebarBtn">Registro de Temas de Visita</div></NavLink >
							<NavLink  to="/productos"><div className="sidebarBtn">Catalogo de Productos</div></NavLink >
							<NavLink  to="/campanas"><div className="sidebarBtn">Catalogo de Campañas</div></NavLink >
						 {false && <NavLink  to="/reportes"><div className="sidebarBtn">Programacion de Reportes</div></NavLink >}
					
						</div>}
					</div>
					<div className="content">
					
						<Route exact path="/" component={VisitasScreen} />
						<Route path="/visitas" component={VisitasScreen} />
						<Route path="/clientes" component={ClientesScreen} />
						<Route path="/tipo_tarea" component={TipoTareaScreen} />
						<Route path="/vendedores" component={VendedorScreen} />
						<Route path="/competencia" component={CompetenciaScreen} />
						<Route path="/productos" component={ProductosScreen} />
						<Route path="/tema_visita" component={TemaVisitaScreen} />
						<Route path="/orden_pedido" component={OrdenPedidoScreen} />
						<Route path="/campanas" component={CampanaScreen} />
						<Route path="/reportes" component={ReportesScreen} />
					</div>
				</div>
				</Router>
			</div>
	}
}

export default MainScreen;