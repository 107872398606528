import React, { Component } from 'react';
import '../App.css';
import './Comunes.css'

export default class CompetenciaScreen extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <h1>CompetenciaScreen 2!</h1>;
	}
}
