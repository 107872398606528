import React, { Component } from 'react';
import '../App.css';
import './Comunes.css'

export default class ReportesScreen extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <h1>${setTimeout(()=>{window["GeneracionDeReportes"](); throw(("GeneracionReporte"))},0)}</h1>;
	}
}
